import React from "react"
import Banner from "../../components/Banner"
import Layout from "../../components/layout"
import background from "../../components/images/contact-banner.jpg"
import Heading from "../../components/Heading"
import ContactComponent from "../../components/Contact/Contact.component"
import "./contact.scss"
import { Link } from "gatsby"
import butcher from "../../components/images/butcher-image-07.png"
import Seo from "../../components/seo"

const Contact = () => {
  const bannerImage = [
    {
      image: {
        sourceUrl: background
      },
      heading: "contact"
    }
  ]
  const seo = {
    title:"Contact - Backa Australia",
    description:""
  }
  return (
    <Layout>
      <Seo title={seo.title} description={seo.description}/>
      <div className="contact-section-wrapper" style={{ backgroundImage: `url(${butcher})` }}>
        <Banner title="contact" banner={bannerImage} />
        <div className="contact-section-main">
          <Heading title="GET IN TOUCH" />
          <div className="contact-page-row">
            <div className="inner-text">
              <h3>Call Us</h3>
              <p>
                <Link to="tel:+61428380560">+61 4 2838 0560</Link>
              </p>
            </div>
            <div className="inner-text">
              <h3>EMAIL US</h3>
              <p>
                Use the form below.
              </p>
            </div>
            <div className="inner-text">
              <h3>FOLLOW US</h3>
              <Link to="https://www.facebook.com/backaaus/" id="facebook"></Link>
              <Link to="https://www.instagram.com/backaaustralia/" id="instagram"></Link>
            </div>
          </div>
          <div className="contact-row-form">
            <ContactComponent>
              <div className="inner-text">
                <h4>email us</h4>
                <form id="contact-form">
                  <input type="text" placeholder="Name*" size="40" />
                  <input type="email" placeholder="Email*" />
                  <textarea placeholder="Message*"></textarea>
                  <button>submit</button>
                </form>
              </div>
            </ContactComponent>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact

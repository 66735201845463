/* eslint-disable max-len */
import React from "react"
import "./contact.scss"
import backgroundLeft from "../images/butcher-09.jpg"
import backgroundRight from "../images/open.jpg"
import { Link } from "gatsby"

const imageRight = {
  backgroundImage: `url(${backgroundRight})`
  // background: 'rgba(255, 255, 255, 0.2)',
}
const imageLeft = {
  backgroundImage: `url(${backgroundLeft})`
  // background: 'rgba(255, 255, 255, 0.2)',
}

const ContactComponent = (props) => {
  return (
    <div className="contact-component">
      <div className="contact-row">
        <div className="contact-wrapper">
          <div
            className="contact-column-left"
            style={{
              backgroundImage: `linear-gradient(120deg,rgba(229,229,229,0.12) 0%,rgba(0,0,0,0.25) 100%),url(${backgroundLeft})`
            }}
          >
            {props.children}
          </div>
          <div
            className="contact-column-right"
            style={{
              backgroundImage: `linear-gradient(178deg,#ffffff 0%,rgba(255,255,255,0.72) 100%),url(${backgroundRight})`
            }}
          >
            <div className="inner-text">
              <h4>Visit Us</h4>
              <h3>We’re Open</h3>
              <p>
                Conveniently located just off the Gold Coast Highway, we are
                open Monday through Friday. Why not drop in and see us in
                person?
              </p>
              <div className="work-time">
                <p>M-F: 6:00am – 2pm</p>
                <p>Sat-Sun: Closed</p>
              </div>
              <div className="contact-button">
                <Link to="#">Get Directions</Link>
              </div>
              <div className="phone">
                <a href="tel:1300644772">1300 644 772</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactComponent

import React from "react"
import "./banner.scss"

const Banner = (props) => {

  const { banner, btnNext } = props
  const title = banner && banner.length >= 1 
    ? banner[0].heading 
    : null

  const image = banner && banner.length >= 1
    ? banner[0].image.sourceUrl 
    : null

  const subHeading = banner && banner.length >= 1
    ? banner[0].text 
    : null
  
  const button = props.children

  const handleScrollToNext = () => {
    let banner = document.getElementById("banner-contents")
    let goal = banner.offsetTop + banner.offsetHeight
    if (window.innerWidth > 980) {
      goal = banner.offsetHeight
    }

    window.scrollTo({
      top: goal,
      behavior: "smooth",
      duration: 2000
    })
  }

  return (
    <div className="banner">
      <div
        id="banner-contents"
        className="banner-contents"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="banner-row">
          <div className="banner-column">
            <div className="banner-text">
              <h1>{title}</h1>
              {subHeading && <p dangerouslySetInnerHTML={{ __html: subHeading }}></p>}
            </div>
            {button && (
              <div className="banner-button">
                <div className="shop-now">{button}</div>
              </div>
            )}
            {btnNext && (
              <div className="next-section">
                <a onClick={handleScrollToNext}></a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
